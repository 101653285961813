import { Alert, Box } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FunctionField,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useDelete,
  useNotify,
  usePermissions,
  useRefresh,
  useShowController,
} from 'react-admin'
import { Role } from 'src/authProvider'
import ImgurImageInput from 'src/Components/RemoteImageInput'
import SmallImageField from 'src/Components/SmallImageField'

import { QRCodeGenerator, Store立牌 } from './qrcode'

interface EditCupNumRequest {
  note: string
  clean_number: number
  dirty_number: number
}
interface EditCupNumFormInput {
  noteInput: string
  addCleanInput: string
  addDirtyInput: string
}
function editCupNumFormTransfrom(
  input: EditCupNumFormInput
): EditCupNumRequest {
  return {
    note: input.noteInput,
    clean_number: Number(input.addCleanInput),
    dirty_number: Number(input.addDirtyInput),
  }
}

export const StoreEdit = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { permissions } = usePermissions()

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props)
  const [addCleanInput, setAddCleanInput] = React.useState('')
  const [addDirtyInput, setAddDirtyInput] = React.useState('')
  const [noteInput, setNoteInput] = React.useState('')

  const [create, createMutation] = useCreate()
  function submitEditCupNumForm() {
    const data = editCupNumFormTransfrom({
      addCleanInput,
      addDirtyInput,
      noteInput,
    })

    create(
      `stores/cup_num?store_id=${record?.id}`,
      {
        data: data,
      },
      {
        onSuccess: () => {
          notify('成功！')
          refresh()
          setAddCleanInput('')
          setAddDirtyInput('')
          setNoteInput('')
        },
        onError: (error) => {
          notify(`使用錯誤： ${JSON.stringify(error)} `, {
            type: 'warning',
          })
        },
      }
    )
  }

  const [editCupNumOpen, setEditCupNumOpen] = useState(false)
  const [selectedCupNum, setSelectedCupNum] = useState<any>()
  const selectedCupTypeId = selectedCupNum?.cup_type_id

  const inputCommonProps = {
    variant: 'outlined',
    size: 'medium',
    InputLabelProps: {
      shrink: true,
    },
  } as const
  return (
    <>
      <Edit redirect={false} {...props}>
        <SimpleForm>
          <FunctionField
            render={(record) => {
              if (record?.active === false) {
                return (
                  <Alert sx={{ marginBottom: 4 }} severity="warning">
                    此店家已封存，無法使用借還等功能
                  </Alert>
                )
              }
              return ''
            }}
          />
          <TextInput label="名稱" source="store_name" validate={required()} />
          <ImgurImageInput source="image_url" />

          {permissions === 'admin' && (
            <ReferenceInput source="default_cup_type_id" reference="cup_type">
              <AutocompleteInput
                optionText="name"
                // disabled={selectedCupType !== undefined}
                // defaultValue={selectedCupType}
              />
            </ReferenceInput>
          )}
          <ArrayField label="杯子數" source="cup_nums">
            <Datagrid
              bulkActionButtons={false}
              rowClick={(_a, _b, record) => {
                setSelectedCupNum(record)
                setEditCupNumOpen(true)
                return false
              }}
              sort={{ field: 'cup_type_id', order: 'ASC' }}
            >
              <SmallImageField label="杯款" source="cup_type.image_url" />
              <TextField label="杯款" source="cup_type.name" />
              <TextField label="乾淨杯子數" source="clean_number" />
              <TextField label="待回收杯子數" source="dirty_number" />
            </Datagrid>
          </ArrayField>
          {permissions === 'admin' && (
            <Button onClick={() => setEditCupNumOpen(true)}>新增杯款</Button>
          )}

          <br />
          <ArrayField label="物流收送紀錄" source="cup_num_records" fullWidth>
            <Datagrid bulkActionButtons={false}>
              <DateField label="時間" source="create_time" showTime />
              <TextField label="杯款" source="cup_num.cup_type.name" />
              <TextField label="乾淨杯" source="clean_number_change" />
              <TextField label="待回收杯" source="dirty_number_change" />
              <TextField label="備註" source="note" />
            </Datagrid>
          </ArrayField>
          <br />
          <br />
          <TextInput disabled source="id" />
          <TextInput label="名稱" source="store_name" validate={required()} />
          <TextInput label="Phone" source="phone" />
          <TextInput label="email" source="email" />
          <TextInput label="詳細資訊" source="info" multiline />
          <TextInput label="地址" source="address" />
          <TextInput label="緯度" source="latitude" />
          <TextInput label="經度" source="longitude" />
          <TextInput label="網站連結" source="website" />
          <SelectInput
            source="store_type"
            choices={[
              { id: 'store', name: '店家' },
              { id: 'box', name: '歸還箱' },
            ]}
          />
          <BooleanInput label="可借" source="rent_available" />
          <BooleanInput label="可還" source="return_available" />
          <BooleanInput
            label="借用時是否需要杯子id"
            source="cup_id_rent_verified"
            helperText={'注意杯子id需事先登入在系統'}
          />
          <BooleanInput
            label="歸還時是否需要杯子id"
            source="cup_id_return_verified"
            helperText={false}
            sx={{
              mb: 0,
              pb: 0,
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              mb: 4,
              mt: 0,
              whiteSpace: 'pre-line',
            }}
          >
            {`開啟後，杯子id鎖N小時，避免重複歸還
須開啟店家的"歸還時是否需要杯子id" (cup_id_return_verified)
預設情況為關閉店家的"借用時是否需要杯子id" (cup_id_rent_verified)
N小時內：
Ex1: 借杯(?) > 還杯(cup1) > 借杯(?) > 還杯(cup1) --- X
Ex2: 借杯(?) > 還杯(cup1) > 借杯(?) > 還杯(cup2) --- OK
    `}
          </Typography>

          {permissions === 'admin' && (
            <BooleanInput
              label="歸還時是否需要拍照"
              source="return_photo_needed"
              helperText="歸還時，消費者必須要拍包含杯子與歸還點的照片。上傳的照片可在後台單個借用紀錄或歸還紀錄裡面看到"
            />
          )}
          <BooleanInput label="是否需要定位" source="geolocation_needed" />
          <BooleanInput
            label="啟用中"
            helperText="若未啟用（封存狀態），則無法在此店家借還"
            source="active"
          />
          <NumberInput label="顯示順序" source="display_order" />
          <ArrayField label="權限" source="store_managements">
            <Datagrid bulkActionButtons={false}>
              <TextField label="人員" source="staff.username" />
              <DateField label="時間" source="create_time" showTime />
            </Datagrid>
          </ArrayField>
        </SimpleForm>
      </Edit>

      <Dialog
        open={editCupNumOpen}
        onClose={() => {
          setEditCupNumOpen(false)
          setSelectedCupNum(undefined)
        }}
      >
        {editCupNumOpen && (
          <Create
            sx={{ mt: 2, mb: 16 }}
            resource="stores/cup_num"
            redirect={false}
            mutationOptions={{
              onSuccess: () => {
                notify('成功！')
                refresh()
              },
            }}
          >
            <SimpleForm
              toolbar={
                <Toolbar
                  sx={{
                    '&.RaToolbar-mobileToolbar': { position: 'relative' },
                  }}
                >
                  <SaveButton />
                  {/* <DeleteCupNumButton id={selectedCupNum} /> */}
                </Toolbar>
              }
            >
              <Typography variant="h6" sx={{ py: 3 }}>
                {selectedCupNum ? '編輯' : '新增'}杯款
              </Typography>
              <TextInput
                sx={{ display: 'none' }}
                disabled
                label="店家id"
                source="store_id"
                defaultValue={record?.id}
              />

              <ReferenceInput source="cup_type_id" reference="cup_type">
                <AutocompleteInput
                  optionText="name"
                  disabled={selectedCupTypeId !== undefined}
                  defaultValue={selectedCupTypeId}
                />
              </ReferenceInput>

              <SmallImageField
                label="杯款"
                size="medium"
                record={selectedCupNum}
                source="cup_type.image_url"
                fieldSx={{
                  marginBottom: 4,
                }}
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NumberInput
                    {...inputCommonProps}
                    source="clean_number"
                    label="可使用"
                    type="number"
                    format={(change) =>
                      (selectedCupNum?.clean_number ?? 0) + Number(change)
                    }
                    parse={(v) =>
                      Number(v) - (selectedCupNum?.clean_number ?? 0)
                    }
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    {...inputCommonProps}
                    source="clean_number"
                    label="調整值"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>

                <Grid item xs={6}>
                  <NumberInput
                    {...inputCommonProps}
                    source="dirty_number"
                    label="待回收"
                    type="number"
                    format={(change) =>
                      (selectedCupNum?.dirty_number ?? 0) + Number(change)
                    }
                    parse={(v) =>
                      Number(v) - (selectedCupNum?.dirty_number ?? 0)
                    }
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    {...inputCommonProps}
                    source="dirty_number"
                    label="調整值"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    source="note"
                    label="備註"
                    type="text"
                    size="medium"
                    variant="outlined"
                    multiline
                  />
                </Grid>
              </Grid>
            </SimpleForm>
          </Create>
        )}
      </Dialog>

      <Box
        style={{
          maxWidth: '90vw',
          marginTop: 48,
          overflow: 'auto',
        }}
      >
        <p>如字型未正確顯示，請重新整理</p>
        <Store立牌 storeId={record?.id} storeName={record?.store_name} />
      </Box>
      <div style={{ margin: 16, marginTop: 48 }}>
        <QRCodeGenerator storeId={record?.id} />
      </div>

      {permissions === Role.ADMIN && record?.id && (
        <Create
          {...props}
          sx={{ mt: 2, mb: 16 }}
          resource="store_management"
          redirect={false}
        >
          <SimpleForm
            toolbar={
              <Toolbar
                sx={{
                  '&.RaToolbar-mobileToolbar': { position: 'relative' },
                }}
              >
                <SaveButton />
              </Toolbar>
            }
          >
            <Typography variant="h6" sx={{ py: 3 }}>
              新增權限
            </Typography>
            <TextInput
              disabled
              label="店家id"
              source="store_id"
              defaultValue={record.id}
            />
            <ReferenceInput source="staff_id" reference="staff">
              <AutocompleteInput label="人員" />
            </ReferenceInput>
            <DateTimeInput
              disabled
              label="創立時間"
              source="create_time"
              defaultValue={new Date().toISOString()}
            />
          </SimpleForm>
        </Create>
      )}
    </>
  )
}

function DeleteCupNumButton({ id }: { id?: number }) {
  const [deleteOne, { isLoading, error }] = useDelete()
  const handleClick = () => {
    deleteOne('cup_num', { id: id })
  }
  if (!id) {
    return null
  }
  if (error) {
    return <Button disabled={true}>Error</Button>
  }
  return (
    <Button disabled={isLoading} onClick={handleClick}>
      Delete
    </Button>
  )
}
